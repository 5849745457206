import useLocale from '@hooks/useLocale';
import useStore from '@hooks/useStore';
import Section from '@molecules/Section/Section';
import { isNimblDomain } from '@utils/getDomain';
import { useEffect, useRef } from 'react';

export default function CookieDeclarationSection() {
  const wrapper = useRef<HTMLDivElement>(null!);
  const locale = useLocale();

  const {
    store: { host },
  } = useStore();

  useEffect(() => {
    const id = isNimblDomain(host)
      ? process.env.COOKIEBOT_ID_NIMBL
      : process.env.COOKIEBOT_ID_ANCHR;
    if (!id) return;

    const script = document.createElement('script');
    script.id = 'CookieDeclaration';
    script.dataset.culture = locale;
    script.src = `https://consent.cookiebot.com/${id}/cd.js`;
    script.type = 'text/javascript';
    script.async = true;

    wrapper.current.appendChild(script);
  }, [locale]);

  return (
    <Section>
      <div ref={wrapper} />
    </Section>
  );
}
